body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
}

/* * {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
} */

h1 {
    margin: 0;
    color: #202630;
    font-size: 1.75em;
}

h2 {
    margin: 0;
    color: #202630;
}

h3 {
    margin: 0;
    color: #202630;
    font-weight: 500;
}

p {
    margin: 0;
    color: #202630;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
