.editable h1 {
    margin: 0;
    color: #202630;
}

.editable h2 {
    margin: 0;
    color: #202630;
}

.editable h3 {
    margin: 0;
    color: #202630;
    font-weight: 500;
}

.editable p {
    margin: 0;
    color: #202630;
}

.editable[contenteditable='true'] {
    box-sizing: border-box;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.1s ease;
}

.editable[contenteditable='true']:hover {
    box-sizing: border-box;
    border-bottom: 2px solid darkgray;
}

.editable[contenteditable='true']:focus {
    box-sizing: border-box;
    outline: 0px solid transparent;
    border-bottom: 2px solid gray;
}
