.row-editable h1 {
    margin: 0;
    color: #202630;
}

.row-editable h2 {
    margin: 0;
    color: #202630;
}

.row-editable h3 {
    margin: 0;
    color: #202630;
    font-weight: 500;
}

.row-editable p {
    margin: 0;
    color: #202630;
}

.row-editable[contenteditable='true'] {
    box-sizing: border-box;
    /* border-bottom: 2px solid transparent; */
    transition: background-color 0.2s ease;
    width: 100%;
    max-width: 100%;
    border-radius: 6px;
    padding: 5px;
}

.row-editable[contenteditable='true']:hover {
    box-sizing: border-box;
    /* border-bottom: 2px solid darkgray; */
    background-color: rgba(0, 0, 0, 0.025);
}

.row-editable[contenteditable='true']:focus {
    box-sizing: border-box;
    outline: 0px solid transparent;
    /* border-bottom: 2px solid gray; */
    background-color: rgba(0, 0, 0, 0.025);
}


.dropdown-transition-enter {
    transform: scale(0.95);
    opacity: 0;
}

.dropdown-transition-enter-active {
    transform: scale(0.95);
    opacity: 0;
}

.dropdown-transition-enter-done {
    transform: scale(1);
    opacity: 1;
}

.dropdown-transition-exit {
    transform: scale(1);
    opacity: 1;
}

.dropdown-transition-exit-active {
    transform: scale(0.95);
    opacity: 0;
}

.dropdown-transition-exit-done {
    transform: scale(0.95);
    opacity: 0;
}